<template>
    <div class="FundingTeachers">
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
            style="width: 100%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="Itemno" label="项目编号" width="150"></el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="PatNum" label="申请（专利）号" width="150"></el-table-column>
            <el-table-column prop="Patcant" label="申请（专利权）人"></el-table-column>
            <el-table-column prop="Nametion" label="发明（设计）名称"></el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data () {
        return {
            tableData:[],
            pageSize:10,
            totalnum:0,
            currentPage:1,
        }
    },
    methods: {
        closei(){
            this.dialogVisible = false
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
    },
    mounted () {
        
    }
}
</script>
<style scoped>
@import './../../assets/css/teacher/FundingTeachers.css';
</style>
<style>
.FundingTeachers .el-table__body-wrapper{
    min-height: calc(100vh - 320px);
}
.FundingTeachers .el-table__empty-block{
    min-height: calc(100vh - 320px);
}
</style>